<!-- 系统设置 app管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'sys:upgradelist:add'" type="plain" @click="add">新增</ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <AddOrEdit
      @close="addEditDrawerVisible = false"
      v-model="addEditDrawerVisible"
      @finish="searchTableList" />
  </scroll-layout>
</template>

<script>
  import AddOrEdit from './AddOrEdit.vue';
  import { upgradeManage } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'accountManageList',
    components: {
      AddOrEdit,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDropdown',
            key: 'terminalType',
            label: '客户端名称',
            placeholder: '请选择客户端名称',
            configSet: {
              label: 'name',
              value: 'code',
            },
            data: this.terminalTypes,
          },
          // {
          //   comName: 'YkcInput',
          //   key: 'versionNo',
          //   label: '版本号 ',
          //   placeholder: '请输入版本号',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'releaseUrl',
          //   label: '发布地址 ',
          //   placeholder: '请输入发布地址',
          // },
          // {
          //   comName: 'YkcInput',
          //   key: 'updateContent',
          //   label: '描述',
          //   placeholder: '请输入描述',
          // },
        ];
      },
    },
    data() {
      return {
        addEditDrawerVisible: false,
        tableData: [],
        tableColumns: [
          { label: '客户端名称', prop: 'terminalType', minWidth: '100px' },
          { label: '版本号', prop: 'versionNo', minWidth: '100px' },
          { label: '描述', prop: 'updateContent', minWidth: '100px' },
          {
            label: '是否强制更新',
            prop: 'isForceUpdateFlag',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const statusMap = {
                  0: '否',
                  1: '是',
                };
                const status = statusMap[row.isForceUpdateFlag] || '——';
                return <div>{status}</div>;
              },
            },
          },
          {
            label: '是否已启用',
            prop: 'status',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const statusMap = {
                  0: '否',
                  1: '是',
                };
                const status = statusMap[row.status] || '——';
                return <div>{status}</div>;
              },
            },
          },
        ],
        tableTitle: '版本列表',
        tableOperateButtons: [
          {
            enabled: () => code('sys:upgradelist:export'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.terminalType === 'IOS'}
                onClick={() => {
                  window.open(row.releaseUrl, '_blank');
                }}>
                下载地址
              </ykc-button>
            ),
          },
          {
            enabled: () => code('sys:upgradelist:enable'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={Number(row.status) === 1}
                onClick={() => {
                  this.startUpgrade(row);
                }}>
                {Number(row.status) === 1 ? '已启用' : '启用'}
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          terminalType: '',
          // versionNo: '',
          // releaseUrl: '',
          // updateContent: '',
        },
        terminalTypes: [
          {
            name: 'H5',
            code: 'H5',
          },
          {
            name: 'IOS',
            code: 'IOS',
          },
          {
            name: 'Android',
            code: 'Android',
          },
        ],
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      /** 根据条件查询数据 */
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        upgradeManage
          .queryUpgradeList(reqParams)
          .then(res => {
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      // 启用
      startUpgrade(row) {
        upgradeManage
          .editUpgrade({
            id: row.id,
          })
          .then(() => {
            this.searchTableList();
          });
      },
      /** 新增 */
      add() {
        this.addEditDrawerVisible = true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table-cell-wrap-enable {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #02c498;
      border-radius: 50%;
    }
  }
  .ykc-table-cell-wrap-deactivate {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #d0021b;
      border-radius: 50%;
    }
  }
</style>
