import OSS from 'ali-oss';
/**
 * 准备工作
 * https://help.aliyun.com/document_detail/64041.html?spm=a2c4g.383947.0.0.1261123eKdEyvH
 * */

export const initOssClient = ossInfo => {
  const client = new OSS({
    region: ossInfo.region, // 创建的时候，bucket所在的区域，华北2->oss-cn-beijing ；其他的可以去百度
    accessKeyId: ossInfo.accessKeyId, // 阿里云控制台创建的AccessKey
    accessKeySecret: ossInfo.accessKeySecret, // 阿里云控制台创建的AccessSecret
    stsToken: ossInfo.stsToken, // 临时安全令牌
    bucket: ossInfo.bucket, // 创建的bucket的名称
    timeout: 5 * 60 * 1000,
  });

  // 上传成功之后，转换真实的地址
  // const signatureUrl = async ObjName => {
  //   try {
  //     const result = await client.signatureUrl(`${ObjName}`);
  //     return result;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const put = async (ObjName, file) => {
    const path = `${ossInfo.path}${ObjName}`;
    return client.put(path, file);
  };

  const multipartUpload = async (ObjName, file, callback) => {
    const options = {
      // 获取分片上传进度、断点和返回值。
      progress: p => {
        console.log(p);
        callback && callback(p);
      },
      // 设置并发上传的分片数量。
      parallel: 4,
      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024,
    };
    const path = `${ossInfo.path}${ObjName}`;
    return client.multipartUpload(path, file, { ...options });
  };

  // const getFileNameUUID = () => {
  //   function rx() {
  //     return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  //   }
  //   return `${+new Date()}_${rx()}${rx()}`;
  // };

  return {
    client,
    put,
    multipartUpload,
  };
};
