<!-- 系统设置 版本管理 新增或编辑 -->
<template>
  <ykc-drawer
    :show="value"
    destroy-on-close
    :title="drawerTitle"
    @update:show="$emit('input', $event)"
    @open="handleOpen">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form ref="YkcForm" :model="model" :rules="rules">
        <ykc-form-item label="客户端名称" prop="terminalType">
          <ykc-radio
            :data="terminalTypes"
            @change="changeType"
            v-model="model.terminalType"></ykc-radio>
        </ykc-form-item>

        <ykc-form-item v-show="model.terminalType !== 'H5'" label="版本号" prop="versionNo">
          <ykc-input
            v-model="model.versionNo"
            maxlength="50"
            placeholder="请输入版本号"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="是否强制更新" prop="isForceUpdateFlag">
          <ykc-radio
            :data="[
              {
                id: '1',
                name: '是',
              },
            ]"
            v-model="model.isForceUpdateFlag"></ykc-radio>
        </ykc-form-item>

        <ykc-form-item v-show="model.terminalType !== 'IOS'" label="上传安装包" prop="releaseUrl">
          <ykc-upload
            :type="model.terminalType === 'Android' ? 'apk' : 'zip'"
            :size="300"
            :file="appFile"
            @handleRemove="handleRemove"
            @handleChange="fileUpload"></ykc-upload>
        </ykc-form-item>

        <ykc-form-item label="描述" prop="updateContent">
          <ykc-input
            v-model="model.updateContent"
            type="textarea"
            maxlength="500"
            placeholder="请输入描述"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import dayjs from 'dayjs';
  import { upgradeManage } from '@/service/apis';
  import { initOssClient } from '@/service/oss';

  // 是否用ali-oss上传版本包
  const uploadByOss = false;

  const initModel = () => ({
    terminalType: 'H5',
    versionNo: `${dayjs().format('YY.MM.DD')}.01`,
    isForceUpdateFlag: '1',
    releaseUrl: '',
    updateContent: '',
  });

  export default {
    props: ['value'],
    data() {
      return {
        oss: null,
        appFile: [],
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.onAddDrawerCancel();
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.beforeEnsure();
            },
          },
        ],
        drawerTitle: '版本新增',
        terminalTypes: [
          {
            id: 'IOS',
            name: 'IOS',
          },
          {
            id: 'Android',
            name: 'Android',
          },
          {
            id: 'H5',
            name: 'H5',
          },
        ],
        model: initModel(),
        rules: {
          terminalType: [{ required: true, message: '请选择客户端名称', trigger: 'blur' }],
          versionNo: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
          isForceUpdateFlag: [{ required: true, message: '请选择是否强制更新', trigger: 'blur' }],
          releaseUrl: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.model.terminalType === 'IOS' || this.model.releaseUrl) {
                  callback();
                } else {
                  callback(new Error('请上传安装包'));
                }
              },
            },
          ],
          updateContent: [{ required: true, message: '请输入描述信息', trigger: 'blur' }],
        },
      };
    },
    methods: {
      // 抽屉展示
      handleOpen() {
        this.model = initModel();
        this.changeType();
        this.drawerTitle = '新增版本信息';
        this.appFile = [];
      },
      changeType() {
        this.rules.releaseUrl[0].required = this.model.terminalType !== 'IOS';
        this.rules.versionNo[0].required = this.model.terminalType !== 'H5';
        this.$refs.YkcForm?.clearValidate();
        this.appFile = [];
        this.model.releaseUrl = '';
      },
      getOss() {
        return new Promise((resolve, reject) => {
          upgradeManage
            .getOssConfig()
            .then(res => {
              this.oss = initOssClient({
                region: res.region,
                stsToken: res.stsToken,
                accessKeySecret: res.accessKeySecret,
                accessKeyId: res.accessKeyId,
                bucket: res.bucket,
                path: `/${res.path}`,
              });
              resolve();
            })
            .catch(() => {
              this.oss = null;
              this.appFile = [];
              reject();
            });
        });
      },
      fileUpload(file, fileList, isCheck) {
        if (isCheck) {
          if (uploadByOss) {
            this.fileUploadByOss(file);
          } else {
            this.fileUploadByApi(file);
          }
        }
      },
      fileUploadByOss(file) {
        // ali-oss上传
        this.getOss().then(() => {
          file.status = 'uploading';
          this.appFile = [file];
          // oss上传
          this.oss
            .multipartUpload(file.name, file.raw, percent => {
              file.percentage = percent * 100;
            })
            .then(res => {
              delete file.status;
              // "http://luoshu-file.oss-cn-hangzhou.aliyuncs.com/support/commonfile/20230401LuoShuTest-2023_04_03_16_17-1.0.0.apk?uploadId=3D4E21B290F149FC846461F3CFA9D077"
              const realUrl = res.res.requestUrls[0].split('?')[0];
              this.model.releaseUrl = realUrl;
              this.appFile = [
                {
                  name: file.name,
                  url: realUrl,
                },
              ];
              this.$refs.YkcForm?.validateField('releaseUrl');
            })
            .catch(err => {
              this.model.releaseUrl = '';
              this.appFile = [];
              if (typeof err !== 'object' || err.status !== 0) {
                this.$message.error(err);
              }
            });
        });
      },
      fileUploadByApi(file) {
        // 接口上传
        const formData = new FormData();
        const param = {
          file: file.raw,
          bizName: '',
        };
        Object.entries(param).forEach(([k, v]) => {
          formData.append(k, v);
        });
        file.status = 'uploading';
        this.appFile = [file];
        upgradeManage
          .uploadFile({
            data: formData,
            onUploadProgress: progressEvent => {
              file.percentage = Number((progressEvent.loaded / progressEvent.total) * 100);
            },
          })
          .then(res => {
            delete file.status;
            this.model.releaseUrl = res;
            this.appFile = [
              {
                name: file.name,
                url: res,
              },
            ];
            this.$refs.YkcForm?.validateField('releaseUrl');
          })
          .catch(err => {
            this.model.releaseUrl = '';
            this.appFile = [];
            this.$message.error(err);
          });
      },
      handleRemove() {
        this.model.releaseUrl = '';
        this.oss?.client?.abortMultipartUpload();
      },
      // 保存
      beforeEnsure() {
        this.$refs.YkcForm.validate(async valid => {
          if (valid) {
            const reqParams = {
              terminalType: this.model.terminalType,
              versionNo: this.model.versionNo,
              isForceUpdateFlag: '1',
              releaseUrl: this.model.releaseUrl,
              updateContent: this.model.updateContent,
            };
            if (this.model.terminalType === 'IOS') {
              delete reqParams.releaseUrl;
            }
            upgradeManage.addUpgrade(reqParams).then(() => {
              this.$emit('close');
              this.$emit('finish');
            });
          }
        });
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel() {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            this.$emit('close');
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
